var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"indigo darken-4",attrs:{"id":"login"}},[_c('v-main',[_c('v-container',{attrs:{"id":"container","fill-height":""}},[_c('v-row',{staticClass:"mb-16 mb-sm-0 align-center"},[(_vm.$vuetify.breakpoint.width > 1263)?_c('v-col',{staticClass:"white--text",attrs:{"cols":"7"}},[_c('p',{staticClass:"text-h2 font-weight-thin"},[_vm._v("¡El empleo de tus")]),_c('p',{staticClass:"text-h2 font-weight-thin"},[_vm._v("sueños esta a solo")]),_c('p',{staticClass:"text-h2 font-weight-bold"},[_vm._v("un clic de distancia!")])]):_vm._e(),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"5"}},[_c('v-card',{staticClass:"pa-6 rounded-xl login-card mx-auto",attrs:{"elevation":"2","max-width":"550","min-width":"350"}},[_c('div',{staticClass:"d-flex justify-center align-center mb-2"},[_c('img',{attrs:{"src":require("../../assets/img/logo-secretaria.png"),"width":"300px"}})]),_c('h1',{staticClass:"text-center",staticStyle:{"color":"#1C1E4D"}},[_vm._v("Iniciar sesión")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.login()}}},[_c('v-card-actions',{staticClass:"justify-center text-center flex-column"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Inicia sesión con Identidad Digital ")]),_c('v-btn',{staticClass:"rounded-pill text-capitalize my-2",attrs:{"color":"#1C1E4D","width":"210","large":"","loading":_vm.loading},on:{"click":function($event){_vm.goToIdentidadDigital(
                    `${_vm.BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${_vm.CLIENT_ID}&redirect_uri=${_vm.REDIRECT_URL.replace(
                      '/',
                      '%2F'
                    )}`
                  )}}},[_c('v-img',{attrs:{"height":"18","width":"160","contain":"","src":require("../../assets/img/Logo_ID.png")}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-footer',{staticClass:"footer text-center transparent pt-4",attrs:{"app":"","absolute":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.height > 550 && _vm.$vuetify.breakpoint.width > 400
      ),expression:"$vuetify.breakpoint.height > 550 && $vuetify.breakpoint.width > 400\n      "}],attrs:{"cols":"12","sm":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-img',{attrs:{"max-height":"150","src":require("../../assets/img/Logo_GOES_horizontal_L.svg"),"contain":""}})],1),_c('v-col',{staticClass:"d-flex justify-center",staticStyle:{"gap":"20px"},attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.redirectTo('https://www.facebook.com/PresidenciaSV/?locale=es_LA')}}},[_c('v-img',{staticClass:"mx-3",attrs:{"src":require("@/assets/img/icons/facebook.svg"),"width":"40"}})],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.redirectTo('https://twitter.com/i/flow/login?redirect_after_login=%2FPresidenciaSV')}}},[_c('v-img',{staticClass:"mx-3 color-logo",attrs:{"src":require("@/assets/img/icons/twitterx.svg"),"width":"40"}})],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.redirectTo('https://www.youtube.com/@GobiernoSV/videos')}}},[_c('v-img',{staticClass:"mx-3",attrs:{"src":require("@/assets/img/icons/youtube.svg"),"width":"40"}})],1)],1)],1),(_vm.loader)?_c('app-loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }