<template>
  <v-app id="login" class="indigo darken-4">
    <v-main>
      <v-container id="container" fill-height>
        <v-row class="mb-16 mb-sm-0 align-center">
          <v-col v-if="$vuetify.breakpoint.width > 1263" cols="7" class="white--text ">
            <p class="text-h2 font-weight-thin">¡El empleo de tus</p>
            <p class="text-h2 font-weight-thin">sueños esta a solo</p>
            <p class="text-h2 font-weight-bold">un clic de distancia!</p>
          </v-col>
          <v-col cols="12" lg="5" class="text-center">
            <v-card elevation="2" class="pa-6 rounded-xl login-card mx-auto" max-width="550" min-width="350">
              <div class="d-flex justify-center align-center mb-2">
                <img src="../../assets/img/logo-secretaria.png" width="300px"/>
              </div>

              <h1 class="text-center" style="color:#1C1E4D">Iniciar sesión</h1>

              <v-form @submit.prevent="login()">

                <v-card-actions class="justify-center text-center flex-column">


                  <span class="grey--text">
                    Inicia sesión con Identidad Digital
                  </span>

                  <v-btn class="rounded-pill text-capitalize my-2" color="#1C1E4D" width="210" large @click="
                    goToIdentidadDigital(
                      `${BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL.replace(
                        '/',
                        '%2F'
                      )}`
                    )
                    " :loading="loading">
                    <v-img height="18" width="160" contain src="../../assets/img/Logo_ID.png"/>
                  </v-btn>


                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer class="footer text-center transparent pt-4" app absolute>
      <v-col cols="12" sm="4" v-show="$vuetify.breakpoint.height > 550 && $vuetify.breakpoint.width > 400
        ">
        <!-- <h4 class="white--text">#ConectandoElSalvador</h4> -->
      </v-col>
      <v-col cols="12" sm="4">
        <v-img max-height="150" src="../../assets/img/Logo_GOES_horizontal_L.svg" contain/>
      </v-col>
      <!--      <v-row class="justify-center social-networks" >-->
      <v-col cols="12" sm="4" class="d-flex justify-center" style="gap: 20px">
        <v-btn icon @click="redirectTo('https://www.facebook.com/PresidenciaSV/?locale=es_LA')">
          <v-img class="mx-3" src="@/assets/img/icons/facebook.svg" width="40"/>
        </v-btn>
        <v-btn icon @click="redirectTo('https://twitter.com/i/flow/login?redirect_after_login=%2FPresidenciaSV')">
          <v-img src="@/assets/img/icons/twitterx.svg" width="40" class="mx-3 color-logo"/>
        </v-btn>
        <v-btn icon @click="redirectTo('https://www.youtube.com/@GobiernoSV/videos')">
          <v-img src="@/assets/img/icons/youtube.svg" width="40" class="mx-3"/>
        </v-btn>
      </v-col>
      <!--      </v-row>-->
    </v-footer>
    <app-loader v-if="loader"></app-loader>
  </v-app>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import {email, required} from "vuelidate/lib/validators";
import jwtDecode from "jwt-decode";
import AppLoader from "@/components/AppLoader.vue";

export default {
  name: "login",
  components: {AppLoader},
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    showPassword: false,
    loading: false,
    loader: false,
    REDIRECT_URL: process.env.VUE_APP_ID_REDIRECT_URL,
    BASE_URL_ID: process.env.VUE_APP_IDENTIDAD_DIGITAL_URL,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
    reset_password: process.env.VUE_APP_IDENTIDAD_DIGITAL_URL + '/password/reset',
  }),
  methods: {
    ...mapActions(["setAuth"]),
    ...mapMutations(["setToken", "setUserInfo"]),
    ...mapMutations("utils", ["setRutas"]),
    redirectTo(link) {
      window.open(link, '_blank');
    },
    async login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const response = await this.services.auth.login(this.form);
          if (response.data.message) {
            this.temporalAlert({
              show: true,
              message: response.data.message,
              type: "success",
            });
            return;
          }
          const {user} = jwtDecode(response.data.token);
          this.setAuth(response?.data);
          if (user?.two_factor_status) {
            this.$router.push({name: "2fa"});
          } else {
            this.$router.push({name: "home"});
          }
        } catch (e) {
        } finally {
          this.loading = false;
        }
      }
    },
    async goToIdentidadDigital(url) {
      // console.log(url);
      window.open(url, "_parent");
    },
    async registro() {
      this.$router.push("/registro");
    },
    async getUserInfo(authorizationCode) {
      try {
        this.loader = true;
        const response = await this.http_client(
            "/api/v1/login-id",
            {code: authorizationCode},
            "post",
        );
        await this.setUserInfo(response?.data);
        if (response.status === 200) {
          localStorage.setItem("token", response.data["token"]);
          localStorage.setItem('paso_formulario', response.data["paso_formulario"])
          // localStorage.setItem("refresh_token", response.data["refreshToken"]);
          // localStorage.setItem("tokenID", response.data["tokenid"]);
          this.setToken(response.data["token"]);


          if (response.data["paso_formulario"] === '0') {
            await this.$router.push({
              name: "oferta-empleo",
              params: {
                email: this.user,
                token: response.data["token"],
                // verified: response.data["verified"],
                // metodos_autenticacion: response.data["metodos_autenticacion"],
              },
            });
          } else {
            await this.$router.push({
              name: "registro",
              params: {
                paso_formulario: response.data["paso_formulario"],
              },
            });
          }
        }
      } catch (e) {
        this.temporalAlert({
          show: true,
          message: e.response.data.message
              ? e.response.data.message
              : "Ocurrió un problema al validar usuario",
          type: "error",
        });
        setTimeout(() => {
          this.temporalAlert({
            message: "",
            show: false,
            type: "",
            timeout: 5000,
          });
        }, 5000);
        this.isValid = true;
        this.dialog = false;
      } finally {
        this.dialog = false;
        this.loader = false;
        this.disabled = false;
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("Correo es obligatorio");
      !this.$v.form.email.email && errors.push("El correo no es valido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
      errors.push("Contraseña es obligatoria");
      return errors;
    },
  },
  async created() {
    this.setRutas([]);
    localStorage.clear();
    if (this.$route?.query?.code) {
      this.mostrarIdentificaciones = false;
      /*
      await this.services.auth
        .GetTokenDigitalIdentity({ code: this.$route?.query?.code })
        .then(async (response) => {
          // await this.decodeToken(response?.data?.token)
          await this.setAuth(response?.data);
        })
        .then(() => {
          this.$router.replace("/home");
        })
        .catch(async () => {
          await this.$router.replace("/identidad-digital");
        });
      */
      this.dialog = true;
      this.autenticado = true;
      await this.getUserInfo(this.$route?.query?.code);
    }
  },
};
</script>

<style scoped>
@media screen and (max-width: 375px) {
  .login-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
  }

  .footer {
    display: none;
  }
}

.texto-position-p1 {
  position: absolute;
  left: 100px;
  bottom: 500px;
}

.texto-position-p2 {
  position: absolute;
  left: 100px;
  bottom: 400px;
}

.texto-position-p3 {
  position: absolute;
  left: 100px;
  bottom: 300px;
}

.texto-position-p4 {
  position: absolute;
  left: 50px;
  bottom: 300px;
}

.texto-position-p5 {
  position: absolute;
  left: 50px;
  bottom: 500px;
}

.texto-position-p6 {
  position: absolute;
  left: 50px;
  bottom: 400px;
}

#login {
  background-image: url("../../assets/img/BGloginWeb.png");
  background-size: cover;
}
</style>
